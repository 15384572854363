export default [
  {
    path: '*',
    meta: { public: true },
    redirect: { path: '/404' }
  },
  {
    path: '/',
    meta: { public: true },
    name: 'Track',
    component: () => import(`@/components/Track.vue`)
  }
]
