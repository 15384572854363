import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import router from './router/'
import vuetify from '@/plugins/vuetify'
import VueObserveVisibility from 'vue-observe-visibility'
import store from './store'
import './scss/fonts.scss'
import './scss/variables.scss'

Vue.use(VueObserveVisibility)

Vue.config.productionTip = false

Vue.filter('formatDateHour', function (value) {
  if (moment(value).isValid()) {
    return moment(value).format('DD/MM/YYYY HH:mm')
  }
  return ''
})

Vue.filter('formatDate', function (value) {
  const [, rawQs] = window.location.href.split('?')
  const qs = Object.fromEntries(
    rawQs.split('&').map((each) => {
      const [key, val] = each.split('=')
      return [key, val]
    })
  )
  const lang = (qs.lg || 'pt-br').toLowerCase()

  if (moment(value).isValid()) {
    if (lang === 'pt-br') return moment(value).format('DD/MM/YYYY')
    return moment(value).format('YYYY-MM-DD')
  }

  return ''
})

Vue.filter('formatMonthDate', function (value) {
  if (moment(value).isValid()) {
    return moment(value).format('DD/MM')
  }
  return ''
})

Vue.filter('formatHour', function (value) {
  if (moment(value).isValid()) {
    return moment(value).format('HH:mm')
  }
  return ''
})

Vue.filter('formatCpf', function (value) {
  if (!value) return ''
  value = value.toString().replace(/\D/g, '')
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
})

Vue.filter('formatCpfCnpj', function (value) {
  if (!value) return ''
  value = value.toString().replace(/\D/g, '')
  if (value.length === 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else {
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    )
  }
})

Vue.filter('firstLetterEachWord', function (str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
})

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
