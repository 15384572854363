import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    home: true,
    history: false,
    ambiental: false,
    blockchain: false,
    seloEcotrace: false,
    certificados: false,
    languageSelector: false,
    prevNavigation: null,
    headerTitle: "Dados do Produto",
    producer_data: null,
    origem: false,
    menu_logo: false,
    lang: false
  },
});
