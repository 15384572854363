<template>
  <div id="appRoot">
    <template>
      <v-app>
        <transition>
          <keep-alive>
            <router-view router-view :key="$route.fullPath" />
          </keep-alive>
        </transition>
      </v-app>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',

  created() {
    this.verifyNewVersion()
  },

  methods: {
    verifyNewVersion() {
      const instance = axios.create()
      instance
        .request({
          method: 'get',
          url:
            window.location.protocol +
            '//' +
            window.location.host +
            '/manifest.json?v=' +
            new Date().getTime(),
          responseType: 'json'
        })
        .then(function(response) {
          if (
            response.data &&
            localStorage.getItem('APP_VERSION', '') != response.data.version_app
          ) {
            localStorage.setItem('APP_VERSION', response.data.version_app)
            window.location =
              window.location.href.indexOf('?') === -1
                ? window.location.href + '?v=' + response.data.version_app
                : window.location.href + '&v=' + response.data.version_app
          }
        })
    }
  }
}
</script>
