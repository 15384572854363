import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import zhHans from "vuetify/src/locale/zh-Hans.ts";
// Translation provided by Vuetify (typescript)
import pt from "vuetify/src/locale/pt.ts";

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3FAB36",
        secondary: "#315CA7",
        tertiary: "#F2F3F7",
        quaternary: "#6A707E",
        quinternary: "#81C683"
      },
      dark: {
        primary: "#3FAB36",
        secondary: "#315CA7",
        tertiary: "#F2F3F7",
        quaternary: "#6A707E",
        quinternary: "#81C683"
      }
    }
  },
  lang: {
    locales: { zhHans, pt },
    current: "pt"
  }
});
